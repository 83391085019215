import React from 'react';
import './index.css'

import { Login, ChatRoom } from './components';

import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

import {useAuthState} from 'react-firebase-hooks/auth';
import {useCollectionData} from 'react-firebase-hooks/firestore';

// Initialise firebase.
firebase.initializeApp({
    apiKey: "AIzaSyAPdcuei6tjcMFciC0IGe13yEkenRb4aIU",
    authDomain: "chatty-fa7a1.firebaseapp.com",
    projectId: "chatty-fa7a1",
    storageBucket: "chatty-fa7a1.appspot.com",
    messagingSenderId: "673724787417",
    appId: "1:673724787417:web:7e44004fabe34ceabe2cc8"
});

// Create our constants.
const auth = firebase.auth();
const firestore = firebase.firestore();

// Home component.
const Home = () => {
    const [user] = useAuthState(auth);
    
    const messageReference = firestore.collection('messages');
    const query = messageReference.orderBy('createdAt', 'desc').limit(25);
    const [messages] = useCollectionData(query, {idField: 'id'});

    // Log the user in with given provider.
    const AttemptLogin = (data) => {
        switch(data){
            case "GOOGLE":
                const provider = new firebase.auth.GoogleAuthProvider();
                auth.signInWithPopup(provider);
                return;
            case "ANON":
                auth.signInAnonymously();
                return;
            default:
                return;
        }
    };

    // Log the user out.
    const LogOut = () => {
        auth.signOut();
    };

    // Send a message to the DB.
    const Send = async(message) => {
        const {uid, photoURL} = auth.currentUser;
    
        await messageReference.add({
            text: message,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            uid,
            photoURL
        });
    };

    return (
        <div className="home">
            {user && (
                <ChatRoom messages={messages} userID={auth.currentUser.uid} Send={Send} LogOut={LogOut} />
            )}
            {!user && (
                <Login firebase={firebase} signin={e => AttemptLogin(e)} />
            )}
        </div>
    );
}

export default Home;