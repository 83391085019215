import React from 'react';
import moment from 'moment';

import './index.css';

const ChatItem = (props) => {
    const {text, uid, photoURL, createdAt} = props.message;
    const messageMode = uid === props.userID ? 'ci-sent' : 'ci-received';

    return (
        <div className="ci-message">
            <div className={`ci-container ${messageMode}`}>
                <div className="ci-left">
                    <img alt="User" src={photoURL === "" || !photoURL ? "https://picsum.photos/id/237/200" : photoURL} className="ci-image" />
                </div>
                <div className="ci-right">    
                    <div className="ci-body">
                        <p>{text}</p>
                    </div>
                    <div className="ci-additional">
                        <p>{createdAt && createdAt.seconds ? moment(createdAt.seconds*1000).fromNow() : "-"}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ChatItem;