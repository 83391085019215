import React, {useRef, useEffect} from 'react';

import './index.css';

import { ChatItem, SendMessage, LogOut } from './components';

const ChatRoom = (props) => {
    const scrollerReference = useRef();

    useEffect(() => {
        scrollerReference.current.scrollIntoView({behaviour: 'smooth'});
    }, [props.messages]);
    
    return (
        <div className="cr-container">
            <div className="cr-header">
                <div className="cr-header-left">
                    <p>Chat Away!</p>
                </div>
                <div className="cr-header-right">
                    <LogOut LogOut={props.LogOut}/>
                </div>
            </div>
            <div className="cr-messages">
                {props.messages && props.messages.slice(0).reverse().map(message => <ChatItem className="cr-chatitem" key={message.id} message={message} userID={props.userID} />)}
                <div ref={scrollerReference}/>
            </div>
            <div className="cr-create">
                <SendMessage Send={props.Send}/>
            </div>
        </div>
    );
}

export default ChatRoom;