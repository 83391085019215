import React from 'react';

import {GoSignOut} from 'react-icons/go';

import './index.css';

const LogOut = (props) => {
    return (
        <div className="buttonContainer">
            <button onClick={e => props.LogOut()}><GoSignOut /></button>
        </div>
    );
}

export default LogOut;