import React from 'react';

import { FcGoogle } from 'react-icons/fc';
import { BsPersonFill } from 'react-icons/bs';

import './index.css';

const Login = (props) => {
    return (
        <div className="li-container">
            <p className="li-header">Hey, want to login?</p>
            <div className="li-buttonContainer">
                <button onClick={e => props.signin("GOOGLE")}><FcGoogle /></button>
            </div>
            <div className="li-buttonContainer">
                <button onClick={e => props.signin("ANON")}><BsPersonFill /></button>
            </div>
        </div>
    );
}

export default Login;