import React, {useState} from 'react';

import { IoIosSend } from 'react-icons/io';

import './index.css'

const SendMessage = (props) => {
    const [message, setMessage] = useState("");
    const Send = (e) => {
        e.preventDefault();
        props.Send(message);
        setMessage("");
    };

    return (
        <form className="sm-container" onSubmit={Send}>
            <div className="sm-input-parent">
                <div className="sm-message-input-container">
                    <input className="sm-message-input" value={message} onChange={e => setMessage(e.target.value)} />
                </div>
            </div>
            <div className="sm-button-parent">
                <div className="sm-buttonContainer">
                    <button type="submit"><IoIosSend /></button>
                </div>
            </div>
        </form>
    );
}

export default SendMessage;